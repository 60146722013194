import VectorMap, { Annotation, Export, Label, Layer, Legend, Source, Title } from 'devextreme-react/vector-map';
import { IVectorMapProps } from 'BCVectorMap/entities/IVectorMapProps';
import * as mapsDataWorld from 'devextreme/dist/js/vectormap-data/world.js';
import * as mapsDataAfrica from 'devextreme/dist/js/vectormap-data/africa.js';
import * as mapsDataCanada from 'devextreme/dist/js/vectormap-data/canada.js';
import * as mapsDataEurasia from 'devextreme/dist/js/vectormap-data/eurasia.js';
import * as mapsDataEurope from 'devextreme/dist/js/vectormap-data/europe.js';
import * as mapsDataUsa from 'devextreme/dist/js/vectormap-data/usa.js';
import React from 'react';
import { SizeMe } from 'react-sizeme';
import { MLHelper } from '@bimser/core';
import * as Hash from "object-hash";

export default (props: IVectorMapProps) => {

  function layerSource(type: string) {
    switch (type) {
      case "World":
        return mapsDataWorld.world;
      case "Africa":
        return mapsDataAfrica.africa;
      case "Canada":
        return mapsDataCanada.canada;
      case "Eurasia":
        return mapsDataEurasia.eurasia;
      case "Europe":
        return mapsDataEurope.europe;
      case "Usa":
        return mapsDataUsa.usa;
      default:
        return mapsDataWorld.world;
    }
  }

  const customizeLayer = (elements) => {
    let selectedValues: any = {}
    let paletTitle = ""
    props.layer.forEach(item => {
      paletTitle = item.colorGroupingField || item.sizeGroupingField
      if (item.data?.length) item.data?.map(i => Object.assign(selectedValues, { [i.label]: { value: i.value, color: i.color } }))

      elements.forEach((element, index) => {
        let items = selectedValues[element.attribute('name')];
        if (items) {
          element.applySettings({
            color: items.color,
            hoveredColor: '#e0e000',
            selectedColor: '#008f00',
            paletteIndex: index % 7
          });
          element.attribute(paletTitle, items.value);
        }
      });
    })
  }

  function customizeText(arg) {
    if (arg.index === 0) {
      return '< 0.5%';
    } else if (arg.index === 5) {
      return '> 3%';
    }
    return `${arg.start}% to ${arg.end}%`;
  }

  function layerGroups(item) {
    if (item?.length) return item.split(",").map(i => parseFloat(i))
  }

  function coordinates(item) {
    if (item) return [item.lat, item.lng]
  }

  function annotationData(item) {
    let data = {}
    if (item?.length) {
      data = item.reduce((acc: any, cur: any) => {
        acc[cur.label] = cur.value;
        return acc
      }, {})
    }
    return data
  }

  function annotationTemplate(annotation: any) {
    const { data } = annotation

    return (
      <svg className="annotation">
        <text x="0" y="60" >
          {Object.keys(data).map(key => {
            return (
              <React.Fragment key={key}>
                <tspan className="caption" dy="14" x="0" >{key}:</tspan>
                <tspan dx="5">{data[key]}</tspan>
              </React.Fragment>
            )
          })}
        </text>
      </svg>
    );
  }

  return (
    <SizeMe monitorWidth monitorHeight >
      {({ size }) =>
        <VectorMap
          id={props.id}
          size={{ width: size.width, height: props.height }}
          bounds={props.bounds}
          controlBar={{ enabled: props.controlBar }}
        >
          <Title
            text={MLHelper.getMLText(props.titleMap?.text)}
            subtitle={MLHelper.getMLText((props.titleMap?.subtitle as any)?.text)}
            font={props.titleMap?.font}
            horizontalAlignment={props.titleMap?.horizontalAlignment}
            verticalAlignment={props.titleMap?.verticalAlignment}
          />
          {props.layer.map((item) =>
            <Layer
              key={item.dataSource}
              dataSource={layerSource(item.dataSource)}
              borderColor={item.borderColor}
              name={item.name}
              colorGroups={layerGroups(item.colorGroups)}
              colorGroupingField={item.colorGroupingField}
              sizeGroups={layerGroups(item.sizeGroups)}
              sizeGroupingField={item.sizeGroupingField}
              customize={customizeLayer}
              palette={item.palette}
              hoverEnabled={item.hoverEnabled}
              hoveredColor={item.hoveredColor}
            >
              <Label key={item.dataSource} enabled={true} dataField="name" />
            </Layer>
          )}
          {props.legend.map((item) =>
            <Legend
              key={Hash(item)}
              title={MLHelper.getMLText(item.title)}
              font={item.font}
              markerShape={item.markerShape}
              horizontalAlignment={item.horizontalAlignment}
              verticalAlignment={item.verticalAlignment}
              customizeText={customizeText}
              visible={item.visible}
            >
              <Source
                key={Hash(item)}
                layer={(item.source as any).layer}
                grouping={(item.source as any).grouping}
              />
            </ Legend >
          )}
          {props.annotation.map(item =>
            <Annotation
              coordinates={coordinates(item.coordinate)}
              key={item.data?.[0].value}
              data={annotationData(item.data)}
              offsetX={item.offsetX}
              offsetY={item.offsetY}
              arrowWidth={item.arrowWidth}
              arrowLength={item.arrowLength}
              color={item.color}
              type="custom"
              width={item.width}
              height={item.height}
              render={annotationTemplate}
            />
          )}
          <Export
            fileName={props.exportMap.fileName}
            formats={props.exportMap.formats.split(",")}
            backgroundColor={props.exportMap.backgroundColor}
            printingEnabled={props.exportMap.printingEnabled}
            enabled={props.exportMap.enabled}
          />
        </VectorMap>
      }
    </SizeMe>
  )
}