import { findMessage } from '../..';
import { IDictionary } from '@bimser/core';

const messages: IDictionary<string> = {
    // Allday Panel
    allDay: findMessage.get("102045"),

    //Appointmen Form
    allDayLabel: findMessage.get("102045"),
    titleLabel: findMessage.get("100126"),
    commitCommand: findMessage.get("100620"),
    moreInformationLabel: findMessage.get("100039"),
    repeatLabel: findMessage.get("100905"),
    notesLabel: findMessage.get("101061"),
    never: findMessage.get("102050"),
    daily: findMessage.get("102051"),
    weekly: findMessage.get("102052"),
    monthly: findMessage.get("102053"),
    yearly: findMessage.get("102054"),
    repeatEveryLabel: findMessage.get("102055"),
    daysLabel: findMessage.get("102056"),
    endRepeatLabel: findMessage.get("102057"),
    onLabel: findMessage.get("102085"),
    afterLabel: findMessage.get("102059"),
    occurrencesLabel: findMessage.get("102060"),
    weeksOnLabel: findMessage.get("102061"),
    monthsLabel: findMessage.get("102062"),
    ofEveryMonthLabel: findMessage.get("102063"),
    theLabel: findMessage.get("102086"),
    firstLabel: findMessage.get("102065"),
    secondLabel: findMessage.get("102066"),
    thirdLabel: findMessage.get("102067"),
    fourthLabel: findMessage.get("102068"),
    lastLabel: findMessage.get("102069"),
    yearsLabel: findMessage.get("102070"),
    ofLabel: findMessage.get("102085"),
    everyLabel: findMessage.get("102072"),
    detailsLabel: findMessage.get("102073"),

    //Confirmation Dialog
    discardButton: findMessage.get("102074"),
    deleteButton: findMessage.get("100013"),
    cancelButton: findMessage.get("100034"),
    confirmDeleteMessage: findMessage.get("102077"),
    confirmCancelMessage: findMessage.get("102078"),

    //Today Button
    today: findMessage.get("100660"),

    //EditRecurrenceMenu
    current: findMessage.get("102080"),
    currentAndFollowing: findMessage.get("102081"),
    all: findMessage.get("102082"),
    menuEditingTitle: findMessage.get("102083"),
    menuDeletingTitle: findMessage.get("102084"),
    commitButton: findMessage.get("100004")
};

export { messages }