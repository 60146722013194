import { EditingState, IntegratedEditing, ViewState } from "@devexpress/dx-react-scheduler";
import { AllDayPanel, AppointmentForm, Appointments, AppointmentTooltip, ConfirmationDialog, CurrentTimeIndicator, DateNavigator, DayView, DragDropProvider, EditRecurrenceMenu, MonthView, Resources, Scheduler, TodayButton, Toolbar, ViewSwitcher, WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import * as React from "react";
import IBaseModule from "../entities/IBaseModule";
import { ISchedulerProps } from "../entities/ISchedulerProps";
import { SchedulerModulePropsKeys } from "../entities/ModulePropsKeys";
import { messages } from '../helpers/getMessages';

export interface ISchedulerModule {
    propKey: SchedulerModulePropsKeys;
    Instance: React.ComponentType<any>;
    order?: number;
}
export default class BCScheduler extends React.Component<ISchedulerProps, null> {
    modules?: Array<ISchedulerModule> = new Array();
    constructor(props: ISchedulerProps) {
        super(props);
        this.getDragDropProvider = this.getDragDropProvider.bind(this);
        this.modules = [
            { Instance: ViewState, propKey: "viewStateProps", order: 0, },
            { Instance: EditingState, propKey: "editingStateProps", order: 0, },
            { Instance: IntegratedEditing, propKey: "integratedEditingProps", order: 0, },
            { Instance: DayView, propKey: "dayViewProps", order: 0, },
            { Instance: WeekView, propKey: "weekViewProps", order: 0, },
            { Instance: MonthView, propKey: "montViewProps", order: 0, },
            { Instance: AllDayPanel, propKey: "allDayPanelProps", order: 0, },
            { Instance: EditRecurrenceMenu, propKey: "editRecurrenceMenuProps", order: 0, },
            { Instance: ConfirmationDialog, propKey: "confirmDialogProps", order: 0, },
            { Instance: Toolbar, propKey: "toolbarProps", order: 0, },
            { Instance: DateNavigator, propKey: "dateNavigatorProps", order: 0, },
            { Instance: TodayButton, propKey: "todayButtonProps", order: 0, },
            { Instance: ViewSwitcher, propKey: "viewSwitcherProps", order: 0, },
            { Instance: Appointments, propKey: "appointmentsProps", order: 0, },
            { Instance: CurrentTimeIndicator, propKey: "currentTimeIndicatorProps", order: 0, },
            { Instance: AppointmentTooltip, propKey: "appointmentTooltipProps", order: 0, },
            { Instance: AppointmentForm, propKey: "appointmentFormProps", order: 0, },
            { Instance: Resources, propKey: "resourcesProps", order: 0, },
        ];
    }

    renderModule(module: ISchedulerModule) {
        if ((this.props[module.propKey] && (this.props[module.propKey] as IBaseModule).enabled) || module.propKey === "appointmentsProps") {
            return <module.Instance key={module.propKey} {...this.props[module.propKey]} messages={messages} />
        }
    }
    renderModules() {
        return this.modules.map((i) => this.renderModule(i));
    }

    getDragDropProvider() {
        if (this.props.dragDropProviderProps?.enabled) {
            return <DragDropProvider key="dragDropProviderProps" {...this.props.dragDropProviderProps} />
        }
        else return null;
    }

    render() {
        return (
            <div className={this.props.className} style={this.props.style}>
                <Scheduler locale={localStorage.getItem("locale")} data={this.props.data}>
                    {this.renderModules()}
                    {this.getDragDropProvider()}
                </Scheduler>
            </div>
        );
    }
}